import React from 'react';
import { Redirect } from 'react-router-dom';
import QueryString from 'query-string';

import MonitoringInfrastructureTable from '../MonitoringAlerts/monitoring_infrastructure_table';
import { PageTitle } from '../PageTitle';
import { DevicesService } from '../../services';

class EnterpriseDevices extends React.Component {

    constructor(props) {
        super(props);
        
        const parsedOptions = QueryString.parse(this.props.location.search);

        this.state = {
            freq: parsedOptions && parsedOptions["freq"] ? parsedOptions["freq"] : null,
            deviceGroupId: parsedOptions && parsedOptions["deviceGroupId"] ? parsedOptions["deviceGroupId"] : null,
            enterpriseId: this.props.match.params && this.props.match.params.enterpriseId ? this.props.match.params.enterpriseId : null
        }
    }

    componentDidMount() {
        let {freq, deviceGroupId} = this.state;
        if (freq && deviceGroupId) {
            DevicesService.getForRate(freq, deviceGroupId).then(response => {
                this.setState({devices: response.devices})
            }).catch(err => {
                console.error(err);
                this.setState({errorMessage: "Unable to load devices"});
            });
        } else { 
            // todo: show all devices for enterprise, or other slices of enterprise devices
            this.setState({errorMessage: "Unable to load devices"});
        }
    }

    render() {
        if (this.state.errorMessage) {
            return (
                <Redirect to={{
                  pathname: '/admin-admin/enterprises',
                  state: {
                    errorMessage: this.state.errorMessage,
                  }
                }}/>
            );
          }
        else if (this.state.devices) {
            return (
                <div>
                    <div className="row">
                    <PageTitle title={`Devices`}
                            backLinkWithParams={{
                                link: {
                                    pathname: this.state.enterpriseId ? `/admin-admin/enterprises/view/${this.state.enterpriseId}` : `/admin-admin/enterprises`,
                                    filterByGroup: this.props.location.state ? this.props.location.state.filterByGroup : null
                                }
                            }} 
                    />
                    </div>
                    <div className="op-content-box">
                        <MonitoringInfrastructureTable
                            data={this.state.devices}
                            showLocation={true}
                        />
                    </div>
                </div>

                )
        } else {
            return (<div className="loader">Loading...</div>)
        }
    }
}

export default EnterpriseDevices;