import React from 'react';
import { sprintf } from 'sprintf-js';
import { RssiHelper } from '../../helpers';
import i18next from "i18next";

const DataTypeDisplayValue = (props) => {
  if (props.value) {
    var value = props.value.stringValue ? props.value.stringValue : props.value.numericValue;
    if (props.value.deviceDataType.token === 'rssi') {
      value = `${value} (${RssiHelper.renderRSSIValue(value, props.deviceTypeToken)})`;
    } else if (props.value.deviceDataType.token === 'magnetometer') {
      value = i18next.t(`magnetometer-data-string-display.${value}`);
    } else if (props.value.deviceDataType.token === 'door_state') {
      value = i18next.t(`door-state-data-string-display.${value}`);
    } else if (props.value.deviceDataType.token === 'power_status') {
      value = i18next.t(`power-status-data-string-display.${value}`);
    } else if (props.value.displayFormat) {
      value = sprintf(props.value.displayFormat, value);
    } 
    return (
      <span>{value}</span>
    );
  } else {
    return (
      <span>{ i18next.t('unknown') }</span>
    );
  }
};

export default DataTypeDisplayValue;
