import config from 'config';
import axios from 'axios';

const ROOT = config.API_BASE_URL;

class DevicesService {

  static getRoot() {
    return ROOT;
  }

  static getCountsByRate(deviceGroupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}devices/count-by-rate?deviceGroupId=${deviceGroupId}`).then((response) => {
        resolve({ result: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getForRate(sampleRate, deviceGroupId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}devices/rate-of/${sampleRate}?deviceGroupId=${deviceGroupId}`).then((response) => {
        resolve({ devices: response.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static findBySerialNumber(options = []) {
    return new Promise((resolve, reject) => {
      const params = options.length == 0 ? '' : `?${options.map(option => Object.keys(option).map((k, i) => `${k}=${option[k]}`)).join('&')}`;
      axios.get(`${ROOT}devices/findBySerialNumber${params}`).then((data) => {
        resolve({ devices: data.data.content, page: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static findUnregisteredBySerialNumber() {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}devices/findUnregisteredBySerialNumber`).then((data) => {
        resolve({ devices: data.data, response: data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static evaluateForMove(deviceId) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}devices/${deviceId}/evaluateForMove`).then((data) => {
        resolve({ data: data.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static moveDevice(deviceId, enterpriseId, allowForce) {
    return new Promise((resolve, reject) => {
      axios.patch(`${ROOT}devices/${deviceId}/moveDevice?enterpriseId=${enterpriseId}&allowForce=${allowForce}`).then((data) => {
        resolve({ device: data.data });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  static getHologramDeviceInfo(imei) {
    return new Promise((resolve, reject) => {
      axios.get(`${ROOT}cellular/hologram/devices?imei=${imei}`).then((response) => {
        resolve(response.data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

}

export default DevicesService;
