const ALL_AVAILABLE_ROUTES = [
    {
        id: 1,
        path: "/monitoring",
        title: "Monitoring",
        icon: "op-icon-monitoring",
        showOnMenu: true,
        children: [
            {
                id: 165,
                path: "/monitoring/admin/dashboard",
                title: "Admin Dashboard",
                icon: null,
                showOnMenu: true,
            },
        ],
    },
    {
        id: 34,
        path: "/admin",
        priority: 4,
        title: "Administration",
        icon: "op-icon-administration",
        showOnMenu: true,
        children: [
            {
                id: 167,
                path: "/admin/scheduler#errors",
                title: "Scheduler Errors",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 162,
                path: "/admin/enterprises/:enterpriseId/devices",
                title: "Enterprise Devices",
                icon: null,
                showOnMenu: false,
            },
        ],
    },
    {
        id: 56,
        path: "/user/profile",
        title: "User Profile",
        icon: null,
        showOnMenu: false,
        children: [
            {
                id: 57,
                path: "/user/profile/notifications",
                title: "Notifications",
                icon: null,
                showOnMenu: false,
            },
        ],
    },
    {
        id: 191,
        path: "/admin-devices",
        title: "Devices",
        icon: "op-icon-devices",
        showOnMenu: true,
        children: [
            {
                id: 193,
                path: "/admin-devices/search",
                title: "Search",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 178,
                path: "/admin-devices/fleets",
                title: "Fleets",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 179,
                path: "/admin-devices/fleets/manage/:fleetId",
                title: "Manage Fleet",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 181,
                path: "/admin-devices/fleets/device/view/:commissionedDeviceReferenceId",
                title: "Fleet Device View",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 171,
                path: "/admin-devices/unregistered-devices",
                title: "Unregistered devices",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 107,
                path: "/admin-devices/devices",
                title: "Reassign devices",
                icon: null,
                showOnMenu: true,
            },
        ],
    },
    {
        id: 192,
        path: "/admin-admin",
        title: "Administration",
        icon: "op-icon-administration",
        showOnMenu: true,
        children: [
            {
                id: 35,
                path: "/admin-admin/enterprises",
                title: "Enterprises",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 36,
                path: "/admin-admin/enterprises/add",
                title: "Add enterprise",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 37,
                path: "/admin-admin/enterprises/edit/:enterpriseId",
                title: "Edit enterprise",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 38,
                path: "/admin-admin/enterprises/view/:enterpriseId",
                title: "View enterprise",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 108,
                path: "/admin-admin/enterprises/:enterpriseId/preferences",
                title: "Enterprise preferences",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 197,
                path: "/admin-admin/search",
                title: "Search",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 109,
                path: "/admin-admin/system-versions",
                title: "View System versions",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 160,
                path: "/admin-admin/enterprises/:enterpriseId/cellular",
                title: "View Cellular Usage for Enterprise",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 114,
                path: "/admin-admin/docs",
                title: "Help Documents",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 115,
                path: "/admin-admin/docs/add",
                title: "Add Document",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 116,
                path: "/admin-admin/docs/edit/:docId",
                title: "Edit Document",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 143,
                path: "/admin-admin/docs/edit/:docId#delete",
                title: "Delete Document",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 158,
                path: "/admin-admin/scheduler",
                title: "Scheduler",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 196,
                path: "/admin-admin/audit-logs",
                title: "Audit Logs",
                icon: null,
                showOnMenu: true,
            },
        ],
    },
    {
        id: 1001,
        path: "/admin-fulfillment",
        title: "Fulfillment",
        icon: "op-icon-administration",
        showOnMenu: true,
        children: [
            {
                id: 1002,
                path: "/admin-fulfillment/orders",
                title: "Order List",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 1003,
                path: "/admin-fulfillment/orders/add",
                title: "Order Add",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 1004,
                path: "/admin-fulfillment/orders/:orderId/edit",
                title: "Order Edit",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 1005,
                path: "/admin-fulfillment/orders/:orderId/view",
                title: "Order View",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 1006,
                path: "/admin-fulfillment/orders/:orderId/packing-slip",
                title: "Packing Slip",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 1007,
                path: "/admin-fulfillment/inventory",
                title: "Inventory List",
                icon: null,
                showOnMenu: true,
            },
            {
                id: 1008,
                path: "/admin-fulfillment/inventory/add",
                title: "Inventory Add",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 1009,
                path: "/admin-fulfillment/inventory/:referenceId/edit",
                title: "Order Edit",
                icon: null,
                showOnMenu: false,
            },
            {
                id: 1010,
                path: "/admin-fulfillment/inventory/:referenceId/view",
                title: "Order View",
                icon: null,
                showOnMenu: false,
            },
        ],
    },
    {
        id: 117,
        path: "/help",
        title: "View Help Documents",
        icon: null,
        showOnMenu: false,
    },
    {
        id: 113,
        path: "/help/:slug",
        title: "Help Detail",
        icon: null,
        showOnMenu: false,
    },
];

export default ALL_AVAILABLE_ROUTES;
