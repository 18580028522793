import { Setup } from '../../components/Setup'; // TODO?

import { Enterprises, EnterpriseAdd, EnterpriseView, EnterpriseEdit, EnterprisePreferences, EnterpriseCellUsage, EnterpriseDevices } from '../../components/Enterprise';
import { AdminDevices, UnregisteredDevices } from '../../components/Device';
import { UserProfile } from '../../components/User';
import { NotificationPreferences } from '../../components/User/NotificationPreference';
import { AdminDashboard } from '../../components/Dashboard';
import { SystemVersions } from '../../components/Version';
import { HelpDetail, HelpListing } from '../../components/Help';
import { Documents, DocumentEdit, DocumentAdd } from '../../components/Documents';
import { Scheduler } from '../../components/ScheduledReports';
import { Fleets, FleetManage, CommissionedDeviceView } from "../../components/Commissioning";
import { DeviceSearch } from '../../components/DeviceSearch';
import { AuditLogsView } from '../../components/AuditLogs';
import { AdminSearchView } from "../../components/AdminSearch";
import { OrderList, OrderAdd, OrderEdit, OrderView, PackingSlip, InventoryList, InventoryAdd, InventoryEdit, InventoryView } from "../../components/Fulfillment";

const PATH_TO_COMPONENTS = {
  '/monitoring/admin/dashboard': AdminDashboard,
  '/admin-admin/enterprises': Enterprises,
  '/admin-admin/enterprises/add': EnterpriseAdd,
  '/admin-admin/enterprises/view/:enterpriseId': EnterpriseView,
  '/admin-admin/enterprises/edit/:enterpriseId': EnterpriseEdit,
  '/admin-admin/enterprises/:enterpriseId/preferences': EnterprisePreferences,
  '/admin-admin/enterprises/:enterpriseId/cellular': EnterpriseCellUsage,
  '/admin-admin/enterprises/:enterpriseId/devices': EnterpriseDevices,
  '/admin-admin/search': AdminSearchView,
  '/admin-admin/system-versions': SystemVersions,
  '/admin-admin/docs': Documents,
  '/admin-admin/docs/add': DocumentAdd,
  '/admin-admin/docs/edit/:docId': DocumentEdit,
  '/admin-admin/scheduler': Scheduler,
  '/admin-admin/audit-logs': AuditLogsView,
  '/admin-devices/search': DeviceSearch,
  '/admin-devices/devices': AdminDevices,
  '/admin-devices/unregistered-devices': UnregisteredDevices,
  '/admin-devices/fleets': Fleets,
  '/admin-devices/fleets/manage/:fleetId': FleetManage,
  '/admin-devices/fleets/device/view/:commissionedDeviceReferenceId': CommissionedDeviceView,
  '/admin-fulfillment/orders': OrderList,
  '/admin-fulfillment/orders/add': OrderAdd,
  '/admin-fulfillment/orders/:orderId/edit': OrderEdit,
  '/admin-fulfillment/orders/:orderId/view': OrderView,
  '/admin-fulfillment/orders/:orderId/packing-slip': PackingSlip,
  "/admin-fulfillment/inventory": InventoryList,
  "/admin-fulfillment/inventory/add": InventoryAdd,
  "/admin-fulfillment/inventory/:referenceId/edit": InventoryEdit,
  "/admin-fulfillment/inventory/:referenceId/view": InventoryView,
  '/help': HelpListing,
  '/help/:slug': HelpDetail,
  '/user/profile': UserProfile,
  '/user/profile/notifications': NotificationPreferences
};

export default PATH_TO_COMPONENTS;
