import React from 'react';
import ReactTable from "react-table";
import moment from 'moment-timezone';
import i18next from 'i18next';

import { DataTypeDisplayValue } from '../DataTypeDisplayValue';
import { TimeFormattingService } from '../../services';

class MonitoringInfrastructureTable extends React.Component {

  constructor(props) {
    super(props);
  }

  renderTime(timestamp) {
    let date = TimeFormattingService.renderDate(timestamp, this.props.grouping && this.props.grouping.timezone && this.props.grouping.timezone ? this.props.grouping.timezone : null);
    let time = TimeFormattingService.renderTime(timestamp, this.props.grouping && this.props.grouping.timezone && this.props.grouping.timezone ? this.props.grouping.timezone : null);
    return <span>{date}<br/>{time}</span>;
  }

  renderReceivedData(row, deviceDataType) {
    const currentValue = row.value.find(data => data.deviceDataType.token === deviceDataType);
    return <DataTypeDisplayValue value={currentValue} deviceTypeToken={row.original.deviceType.token}/>
  }

  sortReceivedData(a, b, type) {
    let dataReceivedA = a.find(data => data.deviceDataType.token === type) ? a.find(data => data.deviceDataType.token === type).numericValue : -999;
    let dataReceivedB = b.find(data => data.deviceDataType.token === type) ? b.find(data => data.deviceDataType.token === type).numericValue : -999;

    return dataReceivedA - dataReceivedB;  
  }

  renderSampleRate(currentMetadata) {
    const sampleRate = currentMetadata.find(metadata => metadata.deviceMetadataType.name === 'sample_rate' || metadata.deviceMetadataType.name === 'freq');

    if (sampleRate && sampleRate.numericValue) {
        const sampleRateInSeconds = sampleRate.numericValue;
        const minutes = Math.floor(sampleRateInSeconds / 60);
        const seconds = sampleRateInSeconds % 60;
        return <div>{moment({minutes, seconds}).format("mm:ss")} ({sampleRateInSeconds}s)</div>;
    }

    return (<div>{ i18next.t('unknown') }</div>)
  }

  sortSampleRate(a, b) {
    let sampleRateA = a.find(metadata => metadata.deviceMetadataType.name === 'sample_rate') ? a.find(metadata => metadata.deviceMetadataType.name === 'sample_rate').numericValue : 9999;
    let sampleRateB = b.find(metadata => metadata.deviceMetadataType.name === 'sample_rate') ? b.find(metadata => metadata.deviceMetadataType.name === 'sample_rate').numericValue : 9999;

    return sampleRateA - sampleRateB;  
  }

  render() {
    return (
      <div className="react-table-custom">
        <ReactTable
          className="-striped -highlight"
          ref="sensorsTable"
          data={this.props.data}
          pageSize={this.props.data.length}
          showPagination={false}
          columns={[
                      {
                        Header: i18next.t('table-headers.location'),
                        accessor: "deviceGrouping.name",
                        sortable: true,
                        minResizeWidth: 100,
                        width: 150,
                        show: this.props.showLocation ? this.props.showLocation : false
                      },
                      {
                        Header: i18next.t('table-headers.sensor-name'),
                        accessor: "displayName",
                        minResizeWidth: 140,
                        sortable: true,
                        filterable: true,
                        filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                        Filter: ({ filter, onChange }) =>
                          <div className="filter">
                              <input
                                onChange={event => onChange(event.target.value)}
                                style={{width: "100%", margin:"0 auto"}}
                                value={filter ? filter.value : ""}
                                placeholder={ i18next.t('form-labels.search-by-name') }
                                className="table-search"
                              />
                          </div>
                      },
                      {
                        Header: i18next.t('table-headers.serial-number'),
                        accessor: "serialNumber",
                        minResizeWidth: 140,
                        sortable: true,
                        filterable: true,
                        filterMethod: (filter, row) => row[filter.id].toLowerCase().includes(filter.value.toLowerCase()),
                        Filter: ({ filter, onChange }) =>
                          <div className="filter">
                              <input
                                onChange={event => onChange(event.target.value)}
                                style={{width: "100%", margin:"0 auto"}}
                                value={filter ? filter.value : ""}
                                placeholder={ i18next.t('form-labels.search-by-serial-number') }
                                className="table-search"
                              />
                          </div>
                      },
                      {
                        Header: i18next.t('table-headers.last-reported'),
                        accessor: "lastUpdatedAt",
                        Cell: row => (this.renderTime(row.value)),
                        sortable: true,
                        minResizeWidth: 100,
                        width: 150
                      },
                      {
                        Header: i18next.t('table-headers.rssi'),
                        id: "rssi",
                        accessor: "deviceLastReceivedData",
                        Cell: row => (this.renderReceivedData(row, "rssi")),
                        sortMethod: (a, b) => this.sortReceivedData(a, b, "rssi"),
                        sortable: true,
                        minResizeWidth: 100,
                        width: 125
                      },
                      {
                        Header: i18next.t('table-headers.lqi'),
                        id: "lqi",
                        accessor: "deviceLastReceivedData",
                        Cell: row => (this.renderReceivedData(row, "lqi")),
                        sortMethod: (a, b) => this.sortReceivedData(a, b, "lqi"),
                        sortable: true,
                        minResizeWidth: 100,
                        width: 125,
                      },
                      {
                        Header: i18next.t('table-headers.battery'),
                        id: "battery",
                        accessor: "deviceLastReceivedData",
                        Cell: row => (this.renderReceivedData(row, "battery")),
                        sortMethod: (a, b) => this.sortReceivedData(a, b, "battery"),
                        sortable: true,
                        minResizeWidth: 100,
                        width: 125
                      },
                      {
                        Header: i18next.t('table-headers.sample-rate'),
                        accessor: "deviceMetadata",
                        Cell: row => (this.renderSampleRate(row.value)),
                        sortMethod: (a, b) => this.sortSampleRate(a, b),
                        sortable: true,
                        minResizeWidth: 100,
                        width: 140
                      }
                    ]}
        />
        </div>
    )
  }
}

export default MonitoringInfrastructureTable;
