import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment-timezone';
import Select from 'react-select';
import { Link, Redirect } from "react-router-dom";

import { PageTitle } from '../PageTitle';
import { DevicesService, EnterprisesService } from '../../services';

class EnterpriseView extends React.Component {

  constructor(props) {
    super(props);

    if (props.match.params.enterpriseId) {
      this.state = {
        enterprise: null,
        enterpriseId: props.match.params.enterpriseId,
        filterByGroup: this.props.location.filterByGroup ? this.props.location.filterByGroup : null
      };
    } else {
      this.state = {
        errorMessage: 'Unable to load enterprise'
      }
    }
  }

  componentDidMount() {
    EnterprisesService.get(this.state.enterpriseId).then((response) => {
      this.setState({ enterprise: response.enterprise });
    }).catch((error) => {
      console.log(error);
      this.setState({errorMessage: 'Unable to load enterprise'});
    });

    EnterprisesService.getDeviceGroups(this.state.enterpriseId).then((response => {
      let groupOptions = response.groups.sort((a, b) => {return a.displayOrder - b.displayOrder}).map(group => {return {value: group.id, label: group.name}});
      let filterByGroup = this.state.filterByGroup ? this.state.filterByGroup : groupOptions[0];
      this.setState({groupOptions, filterByGroup}, () => this.refreshDeviceCountsByRate());
    })).catch((error) => {
      console.error(error);
    });
  }

  refreshDeviceCountsByRate() {
    DevicesService.getCountsByRate(this.state.filterByGroup.value).then((response => {
      let deviceCountsByRate = [];
      Object.keys(response.result).map((freq) => {
        deviceCountsByRate.push({freq: freq, count: response.result[freq]});
      });
      this.setState({deviceCountsByRate})
    })).catch((error) => {
      console.error(error);
    });
  }

  handleGroupUpdate(selectedGroup) {
    this.setState({filterByGroup: selectedGroup}, () => this.refreshDeviceCountsByRate());
  }

  render() {
    if (this.state.errorMessage) {
      return (
          <Redirect to={{
            pathname: '/admin-admin/enterprises',
            state: {
              errorMessage: this.state.errorMessage,
            }
          }}/>
      );
    }
    else if (this.state.enterprise && this.state.groupOptions && this.state.deviceCountsByRate) {
      return (
          <EnterpriseDetail 
            enterprise={this.state.enterprise} 
            groupOptions={this.state.groupOptions}
            filterByGroup={this.state.filterByGroup}
            deviceCountsByRate={this.state.deviceCountsByRate} 
            onGroupUpdate={this.handleGroupUpdate.bind(this)}
          />
      );
    } else {
      return (
          <div className="loader">Loading...</div>
      );
    }
  }

}

class EnterpriseDetail extends React.Component{
  renderSampleRate(sampleRateInSeconds) {

    if (sampleRateInSeconds > 1) {
        const minutes = Math.floor(sampleRateInSeconds / 60);
        const seconds = sampleRateInSeconds % 60;
        return <div>{moment({minutes, seconds}).format("mm:ss")} ({sampleRateInSeconds}s)</div>;
    }

    return (<div>Unknown</div>)
  }

  render() {
    return (
        <div>
          <div className="row">
            <PageTitle title={this.props.enterprise.name}
                       backLink="/admin-admin/enterprises" />
          </div>
          <div className="op-content-box">
            <div className="row">
              <div className="col-sm-6 form-group">
                <div className="card-data-cell remove-border-left card-data-cell-icon card-icon-name">
                  <label>Enterprise Name:</label>
                  <p>{this.props.enterprise.name}</p>
                </div>
              </div>
              <div className="col-sm-6 form-group">
                <div className="card-data-cell remove-border-left card-data-cell-icon card-icon-name">
                  <label>Enterprise Token:</label>
                  <p>{this.props.enterprise.token}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="op-content-box">
            <div className="row">
              <div className="col-sm-8">
                <h4>Devices By Frequency</h4>
              </div>
              <div className="col-sm-4">
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder="Filter by Business Structure"
                    options={this.props.groupOptions}
                    value={this.props.filterByGroup}
                    onChange={this.props.onGroupUpdate.bind(this)}
                    clearable={false}
                    searchable={true}
                  />
              </div>
            </div>
            <div className="react-table-custom">
              <ReactTable
                className="-striped -highlight"
                data={this.props.deviceCountsByRate}
                showPaginationTop
                defaultSorted={[
                  {id: "freq"}
                ]}
                columns={[
                            {
                              Header: "Reporting Frequency",
                              accessor: "freq",
                              Cell: row => (this.renderSampleRate(row.value)),
                              sortable: true,
                              minResizeWidth: 50
                            },
                            {
                              Header: "Device Count",
                              accessor: "count",
                              sortable: true,
                              minResizeWidth: 50
                            }, 
                            {
                              Cell: cell => <div className="text-center">
                                  <Link to={{
                                    pathname: `/admin-admin/enterprises/${this.props.enterprise.id}/devices`,
                                    search: `?deviceGroupId=${this.props.filterByGroup.value}&freq=${cell.original.freq}`,
                                    state: {
                                      filterByGroup: this.props.filterByGroup
                                    }
                                  }}>
                                    <button className="btn btn-sm btn-default">View Details</button>
                                  </Link>
                                </div>,
                              sortable: false,
                              minResizeWidth: 50,
                              width: 150
                            }
                          ]}
                />
              </div>
          </div>
      </div>
    );
  }
}

export default EnterpriseView;
